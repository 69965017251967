import React from "react"
import Logo from "./logo"
import { graphql, Link, useStaticQuery, withPrefix } from "gatsby"

const Footer = ()  => {

  const { strapiMenu } = useStaticQuery(graphql`
      query {
          strapiMenu {
              items {
                  title,
                  link
              }
          }
      }
  `)

  const { items } = strapiMenu

  const menuItemsJsx = items.map((item: any, index: number) => (
    <li
      key={index}
      className="menu-item">
      <Link to={item.link} aria-current="page">{item.title}</Link>
    </li>
  ))

  return (
    <footer className="footer">
      <div className="row">
        <div className="large-12 medium-12 small-12 columns text-center large-text-right">
          <p className="show-for-large">
            <a href="https://www.facebook.com/dentandbeauty/?fref=ts" className="facebook-icon" target="_blank"
               rel="nofollow noopener"><i className="fa fa-facebook" aria-hidden="true"></i></a>
            <a href="https://www.youtube.com/channel/UCrSCUHpcsQ1gvbOxpV_dmcQ" className="youtube-icon" target="_blank"
               rel="nofollow noopener"><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
          </p>
        </div>
        <div className="large-6 medium-12 small-12 columns text-left show-for-large">
          <ul id="menu-menu-stopka">
            {menuItemsJsx}
          </ul>
        </div>
        <div className="large-6 medium-12 small-12 columns text-center large-text-right">
          <div className="margin-bottom20 hide-for-large">
            <div
              className="fb-like"
              data-href="https://www.facebook.com/dentandbeauty/"
              data-layout="button"
              data-action="like"
              data-size="small"
              data-show-faces="false"
              data-share="false"></div>
          </div>
          <p>
            <span>O nasz wizerunek dba: </span><a href="https://brandmed.com/" target="_blank">
            <img
              className="svg"
              src={withPrefix('images/brandmed.svg')}
              alt="Brandmed logo" />
          </a>
          </p>
        </div>
      </div>
      <div id="fb-root"></div>

      <section id="preloader">
        <div id="inner" style={{backgroundImage: `url(${withPrefix(`images/logo2.svg`)}`}}></div>
      </section>
    </footer>
  )
}

export default Footer
