import React from "react"

const Logo = () => (
  <svg version="1.1" id="dentbeauty_logo" x="0px" y="0px" viewBox="0 0 245.003 38.382"
       enableBackground="new 0 0 245.003 38.382">
    <g>
      <g>
        <path fill="#3C3C3B" d="M17.879,29.311c0.444,0.546,1.225,0.82,2.343,0.82v0.828c-0.836-0.077-1.652-0.116-2.448-0.116
              c-1.696,0-3.065,0.187-4.106,0.558v-3.506c-0.489,1.181-1.204,2.082-2.15,2.699c-0.944,0.617-2.098,0.925-3.46,0.925
              c-1.671,0-3.111-0.4-4.318-1.197c-1.208-0.795-2.137-1.991-2.786-3.583C0.306,25.143-0.013,23.171,0,20.819
              c-0.013-2.301,0.386-4.259,1.195-5.878s1.892-2.835,3.249-3.644c1.355-0.81,2.849-1.213,4.481-1.213
              c1.002,0,1.925,0.189,2.767,0.568c0.841,0.379,1.5,0.966,1.975,1.763V4.742c0-1.222-0.222-2.104-0.665-2.65
              c-0.444-0.546-1.225-0.82-2.343-0.82V0.444c0.835,0.077,1.651,0.114,2.448,0.114c1.696,0,3.065-0.185,4.106-0.558v26.661
              C17.215,27.881,17.437,28.765,17.879,29.311z M9.253,10.988c-1.067,0-2.008,0.363-2.825,1.09
              c-0.816,0.726-1.455,1.824-1.918,3.295c-0.463,1.473-0.688,3.287-0.675,5.446c-0.013,3.161,0.433,5.536,1.34,7.122
              c0.905,1.589,2.245,2.38,4.019,2.38c0.681,0,1.323-0.172,1.928-0.519c0.604-0.347,1.13-0.864,1.581-1.552
              c0.449-0.686,0.771-1.512,0.964-2.478V14.034C12.935,12.002,11.463,10.988,9.253,10.988z"></path>
        <path fill="#3C3C3B" d="M39.016,28.81c-0.676,0.804-1.517,1.455-2.526,1.956c-1.008,0.501-2.137,0.753-3.383,0.753
              c-1.877,0-3.498-0.428-4.868-1.282c-1.368-0.854-2.413-2.056-3.132-3.606c-0.72-1.548-1.08-3.332-1.08-5.349
              c0-2.287,0.366-4.273,1.099-5.956c0.733-1.684,1.77-2.979,3.113-3.886c1.343-0.905,2.914-1.358,4.714-1.358
              c2.364,0,4.186,0.716,5.465,2.149c1.279,1.433,1.919,3.608,1.919,6.524H27.999c-0.09,0.682-0.135,1.524-0.135,2.526
              c0,1.735,0.286,3.251,0.858,4.549c0.571,1.299,1.32,2.295,2.245,2.989s1.901,1.039,2.93,1.039c2.647,0,4.581-1.317,5.802-3.951
              l0.79,0.308C40.182,27.142,39.69,28.006,39.016,28.81z M36.211,14.401c-0.27-1.094-0.694-1.96-1.272-2.603
              s-1.292-0.964-2.14-0.964c-0.81,0-1.555,0.26-2.236,0.781c-0.682,0.519-1.256,1.317-1.725,2.39
              c-0.47,1.073-0.769,2.407-0.897,3.999h8.598C36.589,16.694,36.481,15.493,36.211,14.401z"></path>
        <path fill="#3C3C3B" d="M53.811,11.209c1.175-0.751,2.502-1.126,3.98-1.126c1.837,0,3.231,0.493,4.183,1.483
              c0.488,0.54,0.838,1.205,1.051,1.995c0.211,0.791,0.318,1.855,0.318,3.19v10.546c0,0.733,0.099,1.305,0.299,1.715
              c0.199,0.412,0.513,0.702,0.944,0.868c0.431,0.166,1.019,0.252,1.764,0.252v0.828c-2.417-0.103-3.997-0.154-4.742-0.154
              c-0.733,0-2.205,0.051-4.414,0.154v-0.828c0.655,0,1.167-0.085,1.532-0.252c0.367-0.166,0.637-0.457,0.81-0.868
              c0.175-0.41,0.261-0.982,0.261-1.715V15.884c0-0.952-0.074-1.747-0.222-2.39c-0.147-0.643-0.456-1.163-0.925-1.56
              c-0.469-0.4-1.154-0.599-2.052-0.599c-1.08,0-2.04,0.315-2.882,0.944c-0.841,0.631-1.493,1.495-1.956,2.593
              c-0.463,1.1-0.694,2.317-0.694,3.652v8.772c0,0.733,0.087,1.305,0.26,1.715c0.175,0.412,0.446,0.702,0.82,0.868
              c0.372,0.166,0.887,0.252,1.542,0.252v0.828c-2.211-0.103-3.689-0.154-4.434-0.154c-0.746,0-2.326,0.051-4.742,0.154v-0.828
              c0.745,0,1.333-0.085,1.763-0.252c0.431-0.166,0.746-0.457,0.946-0.868c0.199-0.41,0.298-0.982,0.298-1.715V14.941
              c0-1.222-0.221-2.108-0.666-2.662c-0.442-0.552-1.224-0.828-2.342-0.828v-0.828c0.822,0.077,1.639,0.114,2.448,0.114
              c1.67,0,3.039-0.179,4.106-0.54v4.647C51.719,13.173,52.634,11.962,53.811,11.209z"></path>
        <path fill="#3C3C3B" d="M81.49,10.659v0.791H75.65v15.288c0,1.193,0.212,2.051,0.636,2.573c0.424,0.519,1.053,0.779,1.889,0.779
              c0.822,0,1.523-0.327,2.101-0.982c0.578-0.655,1.066-1.735,1.465-3.239l0.771,0.193c-0.643,3.636-2.352,5.456-5.128,5.456
              c-0.887,0-1.619-0.108-2.198-0.319c-0.578-0.213-1.093-0.544-1.542-0.992c-0.566-0.58-0.964-1.289-1.195-2.131
              c-0.231-0.842-0.347-1.976-0.347-3.403V11.451h-3.798v-0.791h3.798V4.626c1.477-0.039,2.66-0.225,3.547-0.558v6.591H81.49z"></path>
      </g>
      <g>
        <path fill="#3C3C3B" d="M126.742,11.191c1.08-0.739,2.301-1.108,3.663-1.108c1.465,0,2.76,0.402,3.884,1.203
              c1.126,0.804,2.009,2.013,2.652,3.624c0.641,1.613,0.964,3.583,0.964,5.909c0,2.275-0.396,4.219-1.187,5.832
              c-0.789,1.613-1.853,2.827-3.19,3.642c-1.337,0.816-2.814,1.226-4.434,1.226c-0.964,0-1.901-0.197-2.814-0.588
              c-0.913-0.392-1.709-0.968-2.39-1.725l-2.814,2.39l-0.655-0.29c0.181-0.643,0.27-1.4,0.27-2.275V4.781
              c0-1.222-0.221-2.104-0.666-2.65c-0.442-0.546-1.224-0.82-2.342-0.82v-0.83c0.836,0.077,1.652,0.118,2.449,0.118
              c1.696,0,3.064-0.187,4.105-0.56v14.265C124.827,12.966,125.663,11.929,126.742,11.191z M126.347,30.111
              c0.791,0.335,1.603,0.501,2.439,0.501c1.773,0,3.097-0.799,3.971-2.4c0.875-1.599,1.311-4.064,1.311-7.392
              c0-3.212-0.396-5.611-1.185-7.189c-0.791-1.583-2.003-2.372-3.634-2.372c-0.706,0-1.394,0.191-2.062,0.568
              c-0.67,0.379-1.264,0.94-1.784,1.678c-0.521,0.739-0.909,1.627-1.167,2.67v12.471C124.853,29.289,125.557,29.776,126.347,30.111z"></path>
        <path fill="#3C3C3B" d="M157.72,28.81c-0.676,0.804-1.516,1.455-2.526,1.956c-1.008,0.501-2.137,0.753-3.383,0.753
              c-1.877,0-3.498-0.428-4.868-1.282c-1.368-0.854-2.413-2.056-3.131-3.606c-0.72-1.548-1.08-3.332-1.08-5.349
              c0-2.287,0.365-4.273,1.098-5.956c0.733-1.684,1.771-2.979,3.113-3.886c1.343-0.905,2.914-1.358,4.714-1.358
              c2.364,0,4.186,0.716,5.465,2.149c1.28,1.433,1.92,3.608,1.92,6.524h-12.337c-0.091,0.682-0.136,1.524-0.136,2.526
              c0,1.735,0.286,3.251,0.858,4.549c0.572,1.299,1.321,2.295,2.246,2.989s1.901,1.039,2.93,1.039c2.646,0,4.58-1.317,5.801-3.951
              l0.791,0.308C158.886,27.142,158.393,28.006,157.72,28.81z M154.915,14.401c-0.27-1.094-0.694-1.96-1.272-2.603
              c-0.578-0.643-1.293-0.964-2.141-0.964c-0.81,0-1.554,0.26-2.236,0.781c-0.682,0.519-1.256,1.317-1.725,2.39
              s-0.769,2.407-0.897,3.999h8.598C155.293,16.694,155.185,15.493,154.915,14.401z"></path>
        <path fill="#3C3C3B" d="M173.748,11.71c-0.637-0.558-1.54-0.838-2.709-0.838c-1.863,0-3.168,0.469-3.912,1.406
              c0.526,0.13,0.966,0.41,1.319,0.84c0.355,0.43,0.532,0.954,0.532,1.571c0,0.668-0.209,1.193-0.627,1.571
              c-0.418,0.379-0.98,0.568-1.686,0.568c-0.67,0-1.193-0.223-1.573-0.674c-0.377-0.45-0.568-1.041-0.568-1.773
              c0-1.029,0.617-1.966,1.851-2.814c0.643-0.424,1.437-0.777,2.38-1.061c0.946-0.282,1.997-0.422,3.153-0.422
              c1.08,0,2.029,0.132,2.853,0.394c0.822,0.264,1.465,0.627,1.928,1.09c0.603,0.617,1.019,1.333,1.242,2.149
              c0.225,0.818,0.339,1.944,0.339,3.385v10.968c0,0.655,0.093,1.13,0.278,1.427c0.187,0.296,0.499,0.442,0.935,0.442
              c0.321,0,0.617-0.069,0.887-0.211c0.27-0.142,0.566-0.347,0.887-0.617l0.444,0.674c-0.463,0.349-0.848,0.617-1.157,0.812
              c-0.308,0.191-0.664,0.345-1.061,0.463c-0.398,0.114-0.86,0.172-1.388,0.172c-1.272,0-2.155-0.319-2.65-0.954
              c-0.495-0.637-0.743-1.487-0.743-2.555c-0.771,1.272-1.696,2.175-2.776,2.709c-1.08,0.532-2.177,0.799-3.295,0.799
              c-0.913,0-1.749-0.158-2.506-0.473c-0.759-0.315-1.366-0.802-1.822-1.465c-0.457-0.662-0.684-1.487-0.684-2.476
              c0-1.002,0.243-1.859,0.733-2.565c0.487-0.706,1.175-1.327,2.062-1.861c0.887-0.532,2.076-1.114,3.567-1.743
              c0.256-0.103,0.887-0.361,1.889-0.771c1.002-0.412,1.725-0.824,2.167-1.234c0.444-0.412,0.666-0.862,0.666-1.351v-2.447
              C174.702,13.313,174.383,12.27,173.748,11.71z M173.738,19.74c-0.45,0.258-1.388,0.733-2.814,1.427
              c-0.759,0.359-1.4,0.73-1.928,1.108c-0.528,0.379-0.976,0.891-1.349,1.534c-0.373,0.641-0.558,1.433-0.558,2.37
              c0,1.144,0.288,2.009,0.866,2.593s1.343,0.877,2.295,0.877c1.747,0,3.23-0.996,4.452-2.987V18.91
              C174.509,19.206,174.188,19.482,173.738,19.74z"></path>
        <path fill="#3C3C3B" d="M202.653,29.35c0.442,0.546,1.224,0.818,2.342,0.818v0.83c-0.824-0.077-1.64-0.116-2.449-0.116
              c-1.696,0-3.064,0.187-4.105,0.56v-4.667c-0.643,1.619-1.54,2.816-2.689,3.588c-1.151,0.771-2.415,1.157-3.788,1.157
              c-1.773,0-3.143-0.503-4.107-1.506c-0.475-0.513-0.812-1.181-1.011-2.005c-0.201-0.822-0.3-1.875-0.3-3.161v-9.906
              c0-1.222-0.219-2.108-0.655-2.662c-0.436-0.552-1.213-0.828-2.331-0.828v-0.828c0.822,0.077,1.638,0.114,2.447,0.114
              c1.658,0,3.028-0.179,4.107-0.54v15.519c0,1.002,0.057,1.812,0.172,2.429s0.392,1.122,0.83,1.514
              c0.436,0.392,1.098,0.586,1.985,0.586c1.015,0,1.928-0.315,2.737-0.944s1.447-1.491,1.909-2.583s0.694-2.301,0.694-3.624v-8.153
              c0-1.222-0.221-2.108-0.666-2.662c-0.442-0.552-1.224-0.828-2.342-0.828v-0.828c0.822,0.077,1.638,0.114,2.447,0.114
              c1.67,0,3.04-0.179,4.107-0.54v16.501C201.988,27.919,202.209,28.804,202.653,29.35z"></path>
        <path fill="#3C3C3B" d="M220.404,10.659v0.791h-5.84v15.288c0,1.193,0.211,2.051,0.635,2.573c0.424,0.519,1.053,0.779,1.889,0.779
              c0.822,0,1.524-0.327,2.102-0.982s1.065-1.735,1.465-3.239l0.771,0.193c-0.643,3.636-2.352,5.456-5.128,5.456
              c-0.887,0-1.619-0.108-2.198-0.319c-0.578-0.213-1.094-0.544-1.542-0.992c-0.566-0.58-0.964-1.289-1.195-2.131
              c-0.231-0.842-0.347-1.976-0.347-3.403V11.451h-3.799v-0.791h3.799V4.626c1.477-0.039,2.66-0.225,3.547-0.558v6.591H220.404z"></path>
        <path fill="#3C3C3B" d="M241.996,10.736c1.169,0,2.171-0.037,3.007-0.114v0.789c-0.528,0.065-0.996,0.302-1.408,0.712
              c-0.412,0.412-0.797,1.1-1.157,2.064l-6.477,16.964l-1.349,3.431c-0.501,1.299-1.067,2.224-1.696,2.776
              c-0.745,0.682-1.773,1.023-3.084,1.023c-0.783,0-1.426-0.211-1.928-0.627c-0.501-0.418-0.751-1-0.751-1.745
              c0-0.629,0.185-1.134,0.558-1.514c0.373-0.379,0.887-0.568,1.542-0.568c0.578,0,1.057,0.16,1.437,0.481
              c0.379,0.323,0.568,0.779,0.568,1.37c0,0.783-0.353,1.376-1.059,1.773c0.051,0.026,0.128,0.039,0.231,0.039
              c1.451,0,2.569-1.029,3.354-3.084l1.406-3.604l-7.171-17.236c-0.45-0.911-0.895-1.51-1.339-1.792
              c-0.442-0.282-0.891-0.424-1.339-0.424v-0.828c1.234,0.166,2.524,0.25,3.874,0.25c1.13,0,2.841-0.083,5.128-0.25v0.828
              c-0.643,0-1.144,0.022-1.504,0.067c-0.359,0.047-0.657,0.168-0.895,0.367c-0.239,0.199-0.357,0.511-0.357,0.933
              c0,0.477,0.166,1.126,0.501,1.948l4.76,11.798l4.357-11.432c0.282-0.771,0.424-1.374,0.424-1.81c0-0.617-0.233-1.073-0.702-1.37
              c-0.471-0.296-1.22-0.475-2.246-0.54v-0.789C240.069,10.7,241.172,10.736,241.996,10.736z"></path>
      </g>
      <g>
        <path className="yellow-path" fill="#FCE500" d="M109.523,16.31c-0.793,0.643-1.957,1.126-3.49,1.449l-0.429,0.147c0.705,0.322,1.177,0.689,1.704,1.273
              c0.534,0.591,0.854,1.271,0.793,2.579c-0.068,1.454-0.289,2.051-1.177,3.123c-0.695,0.838-1.929,1.615-3.136,2.104
              c-1.207,0.489-2.592,0.733-4.156,0.733c-1.692,0-3.181-0.233-4.466-0.699c-1.286-0.466-2.28-1.133-2.983-2.002
              c-0.703-0.868-1.055-1.885-1.055-3.051c0-1.045,0.44-2.251,0.993-2.966c0.552-0.714,1.18-1.258,1.883-1.63
              c0.703-0.372,1.363-0.637,1.979-0.795c-1.744-0.692-2.616-1.662-2.616-2.91c0-0.632,0.216-1.175,0.649-1.63
              c0.432-0.455,1-0.797,1.703-1.026c0.703-0.229,1.453-0.344,2.25-0.344c0.571,0,1.042,0.039,1.41,0.119
              c0.368,0.079,0.699,0.201,0.992,0.366c0.94,0.466,1.41,1.09,1.41,1.872c0,0.301-0.081,0.566-0.243,0.795
              c-0.162,0.229-0.38,0.408-0.654,0.536c-0.275,0.128-0.57,0.192-0.885,0.192c-0.489,0-0.891-0.122-1.207-0.367
              c-0.316-0.244-0.474-0.599-0.474-1.066c0-0.391,0.137-0.742,0.412-1.055c0.274-0.312,0.629-0.506,1.066-0.581
              c-0.135-0.12-0.342-0.22-0.62-0.299c-0.278-0.079-0.583-0.118-0.913-0.118c-0.541,0-1.03,0.113-1.466,0.338
              c-0.436,0.226-0.782,0.566-1.038,1.021c-0.256,0.455-0.383,1.009-0.383,1.664c0,0.887,0.3,1.673,0.902,2.357
              c0.564-0.12,0.996-0.18,1.297-0.18c0.271,0,0.481,0.056,0.632,0.169c0.15,0.113,0.226,0.263,0.226,0.451
              c0,0.143-0.062,0.259-0.186,0.35c-0.124,0.09-0.28,0.135-0.468,0.135c-0.203,0-0.414-0.047-0.632-0.141
              c-0.218-0.094-0.404-0.188-0.558-0.282c-0.154-0.094-0.25-0.152-0.288-0.175c-0.782,0.399-1.385,0.893-1.81,1.483
              c-0.425,0.59-0.637,1.34-0.637,2.25c0,1.489,0.596,2.609,1.788,3.361c1.192,0.752,2.844,1.128,4.957,1.128
              c1.068,0,2.09-0.156,3.068-0.468c0.977-0.312,1.771-0.773,2.379-1.382c0.609-0.609,0.914-1.335,0.914-2.177
              c0-1.398-0.586-2.38-1.759-2.944c-0.444,0.135-0.957,0.284-1.54,0.445c-0.583,0.162-0.972,0.263-1.227,0.319
              c-0.857,0.188-1.562,0.418-2.1,0.617c-0.538,0.199-0.947,0.432-1.229,0.699c-0.282,0.267-0.423,0.588-0.423,0.964
              c0,0.368,0.122,0.694,0.367,0.976c0.244,0.282,0.56,0.5,0.947,0.654c0.387,0.154,0.784,0.231,1.19,0.231
              c0.158,0,0.334-0.023,0.53-0.068c0.195-0.045,0.323-0.098,0.384-0.158c-0.376-0.128-0.662-0.323-0.857-0.586
              c-0.196-0.263-0.294-0.575-0.294-0.936c0-0.474,0.173-0.846,0.519-1.117c0.346-0.271,0.789-0.406,1.33-0.406
              c0.549,0,0.983,0.143,1.303,0.429c0.319,0.286,0.479,0.665,0.479,1.139c0,0.692-0.309,1.214-0.925,1.568
              c-0.617,0.354-1.402,0.53-2.357,0.53c-0.639,0-1.218-0.109-1.737-0.327c-0.519-0.218-0.932-0.549-1.241-0.992
              c-0.308-0.444-0.462-0.989-0.462-1.635c0-1.15,0.44-2.064,1.319-2.741c0.88-0.677,2.161-1.18,3.846-1.511
              c0.286-0.075,0.801-0.203,1.545-0.383c0.744-0.18,1.379-0.361,1.906-0.541c1.188-0.293,2.065-0.613,2.633-0.959
              c0.567-0.346,0.851-0.786,0.851-1.32c0-0.398-0.126-0.759-0.378-1.083c-0.252-0.323-0.579-0.575-0.981-0.756
              c-0.402-0.18-0.821-0.271-1.257-0.271c-0.293,0-0.515,0.038-0.666,0.113c0.353,0.135,0.63,0.338,0.829,0.609
              c0.199,0.271,0.299,0.553,0.299,0.846c0,0.459-0.173,0.821-0.518,1.088c-0.346,0.267-0.79,0.4-1.331,0.4
              c-0.534,0-0.966-0.137-1.297-0.412c-0.331-0.274-0.496-0.649-0.496-1.122c0-0.601,0.261-1.075,0.784-1.421
              c0.523-0.346,1.224-0.519,2.103-0.519c0.722,0,1.359,0.13,1.912,0.389c0.553,0.26,0.983,0.636,1.291,1.128
              c0.308,0.493,0.463,1.073,0.463,1.743C110.714,14.778,110.317,15.667,109.523,16.31z"></path>
      </g>
    </g>
  </svg>
)

export default Logo
