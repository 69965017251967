/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import PreFooter from "./pre-footer"
import Footer from "./footer"
import SEO from "./seo"
import Cookies from "./cookies";

const Layout = ({ children }) => {
  return (
    <>
      <SEO title="Centrum medyczne Dent&Beauty | Świdnica | Umów wizytę" />
      {children}
      <PreFooter />
      <Footer />
      <Cookies/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
