import { withPrefix } from "gatsby"
import React from "react"

const PreFooter = () => {
  return (
    <section className="section citybg show-for-large">
      <div className="row">
        <div className="large-12 medium-12 small-12 columns text-center">
          <p>
            <img src={withPrefix('images/point-svg.svg')} alt="Ikona punkt mapy" />
          </p>
          <h6 className="normal">Świdnica, ul. Waleriana Łukasińskiego 24/02</h6>
        </div>
      </div>
    </section>

)
}

export default PreFooter
