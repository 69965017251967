/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title


  return (
    <Helmet
      htmlAttributes={{
        lang: 'pl-PL'
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: 'robots',
          content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        },
        {
          httpEquiv: `x-ua-compatible`,
          content: "ie=edge"
        },
        {
          name: `keywords`,
          content: "Stomatologia, Medycyna Estetyczna, Dermatologia, Kosmetologia, Dietetyka"
        },
        {
          property: `og:locale`,
          content: 'pl_PL'
        },
        {
          property: `og:type`,
          content: 'website'
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:url`,
          content: 'https://dentandbeauty.pl/'
        },
        {
          property: `og:site_name`,
          content: 'Centrum Medyczne Dent&Beauty | Świdnica'
        },
        {
          property: `og:image`,
          content: withPrefix('images/og-image.jpg')
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: 'google-site-verification',
          content: 'O8jAodRpQnHP9kC4lldVSpTi851wkaTN9aYXjW2Y7PU'
        }
      ].concat(meta)}
      scripts
    >
      <link rel="stylesheet" href={withPrefix("styles/main.css")} />
      <link rel="stylesheet" href={withPrefix("styles/shortcodes-legacy.css")} />
      <script defer src={withPrefix("scripts/jquery.js")} type="text/javascript" />
      <script defer src={withPrefix("scripts/main.js")} type="text/javascript" />
      <script defer type="text/javascript" src="https://maps.googleapis.com/maps/api/js?libraries=places,geometry"></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
