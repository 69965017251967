import React from "react"
import {withPrefix} from "gatsby"

const Cookies = () => {
  return (
    <div id="cookies-box">
      <div className="row">
        <div className="large-10 medium-10 small-10 columns">
          <div>
            Ta strona używa ciasteczek (cookies), dzięki którym nasz serwis może działać lepiej. <span
            className="pokazdetale white underline">Pokaż detale</span>
            <p className="detale">
              Niektóre pliki cookies są wymagane do poprawnego działania tej strony stąd potrzeba ich zapisania w
              pamięci
              podręcznej przeglądarki. Więcej informacji pod adresem <a href="http://wszystkoociasteczkach.pl"
                                                                        target="_blank"
                                                                        rel="nofollow noopener">www.wszystkoociasteczkach.pl</a><br />
              <span className="ukryjdetale white underline">Ukryj detale</span>
            </p>
          </div>
        </div>
        <div className="large-2 medium-2 small-2 columns text-right">
          <span className="close">
          <img src={withPrefix('images/close.svg')}
               alt="Zamknij cookies" />
          </span>
        </div>
      </div>
    </div>
  )
};

export default Cookies
